<!--
 * @Author: jason
 * @Description: 办理进展页面
-->
<template>
    <div>
        <div class="blue-cont">
            <div class="top-title">
                <div class="name">{{ this.$store.state.user.userName }},您好</div>
                <div class="current-progress">当前办理项目: {{ productName }}</div>
                <div class="sign-time">签约时间: {{ signTime }}</div>
            </div>
        </div>

        <div v-if="this.$store.state.type != '随迁'" class="text-box" >
            <div class="project_schedule">
                <div class="title">项目进度</div>
                <!-- 仅留学展示 -->
                <div v-if="$store.state.type ==='留学'" class="input-cont">
                    <el-select v-model="schemePosition" placeholder="请选择方案定位" @change="schemeChange">
                        <el-option v-for="item in options" :key="item.id" :label="item.schoolMajor" :value="item.id" />
                    </el-select>
                </div>
                <div v-if="$store.state.type ==='留学'" class="tips_txt">请切换方案查看项目进度</div>
            </div>

            <div class="icon-cont">
                <!-- 7个标识图标 -->
                <div class="left-icon" :class="currentNode>0?'blue-left':'grey-left'" @click="goPrevious" />
                <div class="process-logo">
                    <div v-for="(item,index) in data" :key="index" class="box">
                        <div class="one-display">
                            <div :class="item.progress==2 ? 'qaz':'img-box'">
                                <i v-if="item.progress==1" class="el-icon-success" @click="clickProgressBtn(item.num - 1)" />
                                <i v-else-if="item.progress==2" class="el-icon-more" @click="clickProgressBtn(item.num - 1)" />
                                <div v-else class="done-img ring" :class="item.progress==1 || item.progress==2 ?'blue-r':'grey-r'" @click="clickProgressBtn(item.num - 1)">{{ item.num }}</div>
                            </div>
                            <div v-if="(index+1)<=(data.length-1)" class="line" :class="(item.progress==1 || item.progress==2)&&data[index+1].progress!=0?'blue-l':'grey-l'" />
                        </div>
                        <div class="explain-text" :class="item.progress==1 || item.progress==2 ?'black-z':'grey-z'" @click="clickProgressBtn(item.num - 1)">{{ item.nodeName }}</div>
                    </div>
                </div>
                <div class="right-icon" :class="(currentNode === totalData.length - pageSize) || (totalData.length < pageSize)?'grey-right':''" @click="goNext" />
            </div>
            <div class="explains">
                <div class="step-title">{{ title }}</div>
                <div class="step-text">{{ remark }}</div>
                <div v-if="showUpLoadBtn" class="step-btn">
                    <el-button type="primary" @click="goUpload">上传资料</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="javascript">
import { progressList, orderPlan, chooseOrderPlan, getGangedProgress } from "@/api/workfow.js";
export default {
    name: "HandleProgress",
    data() {
        return {
            data: [],
            totalData: [],
            progress: 0,
            remark: "",
            signTime: "",
            productName: "",
            options: [],
            title: "",
            limt: "",
            schemePosition: "", //方案定位
            pageSize: 7, //每页展示七个节点
            currentNode: 0, //当前索引
            showStudy: false, //展示优才专才  判断展示留学进度
            smallPro:true,//小项目显示
        };
    },
    computed: {
        // 是否显示上传资料按钮（项目进度部分）
        showUpLoadBtn() {
            switch (this.$store.state.type) {
                case "优才": {
                    if(this.totalData.length>=5 && this.remark === this.totalData.find(item => item.nodeName.includes('基础资料审核')).explain) return true
                    if(this.totalData.length>=8 && this.remark === this.totalData.find(item => item.nodeName.includes('资料确认')).explain) return true
                    return false
                }
                case "专才":
                    if(this.totalData.length>=3 && this.remark === this.totalData.find(item => item.nodeName.includes('上传资料')).explain) return true
                    return false
                default:
                    return false;
            }
        },
    },
    created() {
        this.$store.commit("user");
        this.$store.commit("setType");
        this.$store.commit("setName");
        if(["留学", "优才", "专才", "澳门优才", "小项目-优才"].includes(this.$store.state.type)) {
            this.$store.state.type === "留学"
                ? this.orderPlanFun()
                : this.fetchGangedProgress();
        }

        if (['随迁'].includes(this.$store.state.type)) {
            // 进入随迁人员进度步骤
            if (this.$store.state.name === '新增随迁人员') {
                console.log('this.$store.state.name',this.$store.state.name);
                this.fetchGangedProgress()
            }
        }
    },
    methods: {
        // 获取满足条件最后一条索引
        findLastIndex(array, cb) {
            if(!Array.isArray(array) || array.length === 0) return -1
            for(let i = array.length - 1; i >= 0; i--){
                const item = array[i];
                if (cb.call(null, item, i, array)) {
                return i
                }
            }
            return -1
        },
        // 项目进度-服务端联动
        async fetchGangedProgress() {
            let { code, data } = await getGangedProgress({
                orderId: this.$store.state.user.orderId,
                planId: this.schemePosition,
                productType: this.$store.state.type === '优才' ? 1
                            : this.$store.state.type === '专才' ? 2
                            : 3
            });
            if(code === 200) {
                console.log(data)
                data.progressInfoList = data.progressInfoList.filter(v => v.progress === 1 || v.progress === 2);
                let currentNode = this.findLastIndex(data.progressInfoList, (_) => v => v.progress === 1 || v.progress === 2);
                this.progress = data.progressInfoList[currentNode].progress;
                this.remark = data.progressInfoList[currentNode].explain;
                this.signTime = data.signTime;
                this.productName = data.productName;
                this.totalData = data.progressInfoList.map((v,i) => Object.assign({...v,num: i + 1}));
                // 数据大于7条 默认展示前7条
                this.totalData.length > this.pageSize ?
                    // this.data = data.progressInfoList.slice(0, this.pageSize) :
                    // this.data = this.totalData.slice(0, this.pageSize) :
                    this.data = this.totalData.slice(-7) :
                    this.data = data.progressInfoList.map((v,i) => Object.assign({...v,num: i + 1}));
                this.currentNode = this.totalData.length < this.pageSize ? 0 : this.totalData.length - this.pageSize
            }
        },
        // 方案切换
        schemeChange(e) {
            chooseOrderPlan("?planId=" + e).then((_) => {});
            this.fetchGangedProgress();
        },
        //点击上一个
        goPrevious() {
            this.currentNode = this.currentNode - 1;
            if (this.currentNode <= 0) {
                this.currentNode = 0;
            }
            this.data = this.totalData.slice(this.currentNode, this.currentNode + this.pageSize);

        },
        //点击下一个
        goNext() {
            if((this.currentNode === this.totalData.length - this.pageSize) || (this.totalData.length < this.pageSize)) return;
            this.currentNode = this.currentNode + 1;
            this.data = this.totalData.slice(this.currentNode, this.currentNode + this.pageSize);
        },
        // 留学方案定位下拉框
        orderPlanFun() {
            orderPlan({
                orderId: this.$store.state.user.orderId,
            }).then((res) => {
                if (res.code == 200) {
                    this.options = res.data.orderPlanList;
                    this.fetchGangedProgress();
                }
            });
        },
        //点击进度按钮
        clickProgressBtn(index) {
            if(this.totalData[index].progress === 0) return
            this.remark = this.totalData[index].explain;
            this.title = this.totalData[index].nodeName;
        },
        // 跳转至上传资料
        goUpload() {
            this.$router.push({
                path: "/UploadData",
            })
        },
    },
};
</script>

<style lang="less" scoped>
.blue-cont {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
}

.top-title {
    width: 1000px;
    height: 130px;
    margin: 0 auto;
}

.name {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 1px;
    padding-top: 28px;
    margin-bottom: 12px;
}

.current-progress {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 6px;
}
.tips_txt {
    margin: 10px 20px 0;
    color: #c0c4cc;
}

.sign-time {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
}

.text-box {
    width: 1000px;
    margin: 0 auto;
    padding-top: 40px;
}

.project_schedule {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 38px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #303133;
    letter-spacing: 1px;
    margin-right: 20px;
}

.process-logo {
    display: flex;
    width: 100%;
    padding: 0 20px;
    // justify-content: center;
    align-items: center;
}

.box {
    height: 100px;
}

.explain-text {
    display: inline-block;
    position: relative;
    // left: -24px;
    margin-top: 14px;
    cursor: pointer;
}

.one-display {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.img-box {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    position: relative;

    .el-icon-success {
        color: #409eff;
        font-size: 37px;
        cursor: pointer;
    }

}

.qaz {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    position: relative;
    background: #409eff;
    border-radius: 50%;
    .el-icon-more {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        position: relative;
        left: 6px;
        top: 6px;
    }
}
.done-img {
    width: 100%;
    height: 100%;
}

.ring {
    width: 32px;
    height: 32px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
}

.blue-r {
    background: #409eff;
    color: #ffffff;
    border: 1px solid #409eff;
    border-radius: 16px;
    cursor: pointer;
}

.grey-r {
    color: #c0c4cc;
    border: 1px solid #dcdfe6;
    border-radius: 16px;
}

.black-z {
    color: #303133;
}

.grey-z {
    color: #c0c4cc;
}

.line {
    width: 100px;
    height: 2px;
    margin-left: 8px;
}

.blue-l {
    background: #409eff;
}

.grey-l {
    background: #dcdfe6;
}

.icon-cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 36px;
}

.left-icon {
    width: 24px;
    height: 24px;
    background: url("~@/assets/img/left_slide.png") no-repeat center center;
    margin-top: 5px;
    cursor: pointer;
}

.grey-left {
    background: url("~@/assets/img/left_slide.png") no-repeat center center;
}

.blue-left {
    background: url("~@/assets/img/left_slide_blue.png") no-repeat center center;
}

.right-icon {
    width: 24px;
    height: 24px;
    margin-top: 5px;
    background: url("~@/assets/img/right_slide.png") no-repeat center center;
    cursor: pointer;
}

.grey-right {
    background: url("~@/assets/img/left_slide.png") no-repeat center center;
    transform: rotateY(180deg);
}

.explains {
    width: 100%;
    height: 140px;
    background: #f5f7fa;
    margin-bottom: 236px;
}

.step-title {
    font-size: 16px;
    font-weight: 600;
    color: #303133;
    margin-left: 20px;
    padding-top: 16px;
    letter-spacing: 1px;
}

.step-text {
    font-size: 14px;
    font-weight: 500;
    color: #303133;
    margin-left: 20px;
    margin-top: 8px;
    letter-spacing: 1px;
}

.step-btn {
    margin-left: 20px;
    margin-top: 8px;
}
</style>
